import type { AuthErrorName, AuthErrorProps } from "./AuthError.types";

class AuthError extends Error {
  name: AuthErrorName;
  cause: unknown;

  constructor({ name, message, cause }: AuthErrorProps) {
    super(message);
    this.name = name;
    this.cause = cause;
  }
}

export default AuthError;
