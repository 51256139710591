import type { AxioRefreshTokenProps } from "./AxioRefreshToken.types";

class AxioRefreshToken {
  private token: string;

  constructor({ refreshToken }: AxioRefreshTokenProps = {}) {
    this.token = refreshToken || "";
  }

  getToken() {
    return this.token;
  }
}

export default AxioRefreshToken;
