import AuthError from "../AuthError";
import AxioJwtToken from "../AxioJwtToken";
import AxioRefreshToken from "../AxioRefreshToken";
import AxioUserSession from "../AxioUserSession";
import { ResultAsync } from "neverthrow";
import { COGNITO_EXCEPTION, TOKEN_ENDPOINT_ERROR } from "./Auth.constants";
import { Challenge } from "./Auth.types";

export const getChallenge = (data: object): Challenge => {
  const challengeParam =
    "challengeParam" in data ? data.challengeParam : undefined;

  const challengeName =
    "challengeName" in data && typeof data.challengeName === "string"
      ? data.challengeName
      : undefined;

  return { challengeName, challengeParam };
};

export const getAuthError = (error: unknown): AuthError => {
  if (error instanceof AuthError) {
    return error;
  }

  const isObject = !!(error && typeof error === "object");
  const hasErrorCode =
    isObject && "code" in error && typeof error.code === "string";

  if (hasErrorCode) {
    const code = error.code as string;

    switch (code) {
      case COGNITO_EXCEPTION.USER_NOT_FOUND:
      case COGNITO_EXCEPTION.NOT_AUTHORIZED:
        return new AuthError({
          name: "INVALID_CREDENTIALS",
          message: "Invalid username or password.",
        });
      case COGNITO_EXCEPTION.USER_NOT_CONFIRMED:
        return new AuthError({
          name: "USER_NOT_CONFIRMED",
          message: "User didn't finish the confirmation step when signing up.",
        });
      case COGNITO_EXCEPTION.PASSWORD_RESET_REQUIRED:
        return new AuthError({
          name: "PASSWORD_RESET_REQUIRED",
          message: "Password must be reset before signing in.",
        });
      case COGNITO_EXCEPTION.PASSWORD_HISTORY_POLICY:
      case COGNITO_EXCEPTION.INVALID_PASSWORD:
        return new AuthError({
          name: "INVALID_PASSWORD",
          message: "Invalid password.",
        });
      case COGNITO_EXCEPTION.EXPIRED_CODE:
      case COGNITO_EXCEPTION.CODE_MISMATCH:
        return new AuthError({
          name: "INVALID_CODE",
          message:
            "Provided code doesn't match what the server was expecting or has expired.",
        });
      default:
        return new AuthError({
          name: "UNKNOWN",
          message: `Unknown error code: ${code}`,
        });
    }
  }

  const hasMessage =
    isObject && "message" in error && typeof error.message === "string";

  if (hasMessage) {
    const message = error.message as string;

    return new AuthError({
      name: "UNKNOWN",
      message,
    });
  }

  const isString = !!(error && typeof error === "string");

  if (isString) {
    const errorString = error as string;

    switch (errorString) {
      case TOKEN_ENDPOINT_ERROR.INVALID_GRANT:
        return new AuthError({
          name: "INVALID_GRANT",
          message:
            "Refresh token has been revoked, authorization code has been consumed already, or authorization code does not exist.",
        });
      case TOKEN_ENDPOINT_ERROR.INVALID_REQUEST:
        return new AuthError({
          name: "INVALID_REQUEST",
          message:
            "The request is missing a required parameter, includes an unsupported parameter value, or is otherwise malformed.",
        });
      default:
        return new AuthError({
          name: "UNKNOWN",
          message: `Unknown error: ${errorString}`,
        });
    }
  }

  return new AuthError({
    name: "UNKNOWN",
    message: "Unknown error",
    cause: error,
  });
};

export const getAxioUserSession = (data: object): AxioUserSession => {
  const axioJwtToken = new AxioJwtToken(data);
  const axioRefreshToken = new AxioRefreshToken(data);

  return new AxioUserSession(axioJwtToken, axioRefreshToken);
};

export const encodeCredentials = (
  username: string,
  password: string
): string => {
  return Buffer.from(`${username}:${password}`).toString("base64");
};

export const handleResponse = ResultAsync.fromThrowable(
  async (response: Response) => {
    const json = await response.json();
    const result = typeof json === "object" ? (json as object) : {};

    if (!response.ok) {
      const error = "error" in result ? result.error : result;

      throw error;
    }

    return result;
  },
  getAuthError
);

export const request = ResultAsync.fromThrowable(
  (input: RequestInfo | URL, init?: RequestInit) => fetch(input, init),
  getAuthError
);
