import type { AxioJwtTokenProps } from "./AxioJwtToken.types";

class AxioJwtToken {
  private jwtToken: string;
  private payload: unknown;

  constructor({ token }: AxioJwtTokenProps = {}) {
    this.jwtToken = token || "";
    this.payload = this.decodePayload();
  }

  getJwtToken() {
    return this.jwtToken;
  }

  getEmail() {
    const payload = this.payload;
    const hasEmail = !!(
      payload &&
      typeof payload === "object" &&
      "email" in payload &&
      typeof payload.email === "string"
    );

    return hasEmail ? (payload.email as string) : undefined;
  }

  getCognito() {
    const payload = this.payload;
    const hasCognito = !!(
      payload &&
      typeof payload === "object" &&
      "cognito" in payload &&
      typeof payload.cognito === "object"
    );

    return hasCognito ? (payload.cognito as object) : {};
  }

  getCognitoIdToken() {
    const cognito = this.getCognito();
    const hasIdToken = !!(
      "idToken" in cognito && typeof cognito.idToken === "string"
    );

    return hasIdToken ? (cognito.idToken as string) : undefined;
  }

  getCognitoAccessToken() {
    const cognito = this.getCognito();
    const hasIdToken = !!(
      "accessToken" in cognito && typeof cognito.accessToken === "string"
    );

    return hasIdToken ? (cognito.accessToken as string) : undefined;
  }

  getExpiration() {
    const payload = this.payload;
    const hasEXP = !!(
      payload &&
      typeof payload === "object" &&
      "exp" in payload &&
      typeof payload.exp === "number"
    );

    return hasEXP ? (payload.exp as number) : undefined;
  }

  getIssuedAt() {
    const payload = this.payload;
    const hasIAT = !!(
      payload &&
      typeof payload === "object" &&
      "iat" in payload &&
      typeof payload.iat === "number"
    );

    return hasIAT ? (payload.iat as number) : undefined;
  }

  getUIVersion() {
    const payload = this.payload;
    const hasUIVersion = !!(
      payload &&
      typeof payload === "object" &&
      "uiVersion" in payload &&
      typeof payload.uiVersion === "string"
    );

    return hasUIVersion ? (payload.uiVersion as string) : undefined;
  }

  decodePayload() {
    const payload = this.jwtToken.split(".")[1];

    try {
      return JSON.parse(Buffer.from(payload, "base64").toString("utf8"));
    } catch (err) {
      return {};
    }
  }
}

export default AxioJwtToken;
