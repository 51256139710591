import AxioJwtToken from "../AxioJwtToken";
import AxioRefreshToken from "../AxioRefreshToken";

class AxioUserSession {
  private token: AxioJwtToken;
  private refreshToken: AxioRefreshToken;

  constructor(token: AxioJwtToken, refreshToken: AxioRefreshToken) {
    this.token = token;
    this.refreshToken = refreshToken;
  }

  getToken() {
    return this.token;
  }

  getRefreshToken() {
    return this.refreshToken;
  }

  isValid() {
    const expiration = this.token.getExpiration();

    if (!expiration) {
      return false;
    }

    const now = Math.floor(Date.now() / 1000);

    return now < expiration;
  }
}

export default AxioUserSession;
