/* eslint-disable @typescript-eslint/no-unused-vars */
import AuthError from "../AuthError";
import { err } from "neverthrow";
import { createContext } from "react";
import { DEFAULT_UI_VERSION } from "./AuthProvider.constants";
import type { AuthContextType } from "./AuthProvider.types";

const AuthContext = createContext<AuthContextType>({
  loading: true,
  authenticated: false,
  getSsoStatus: (email: string) => Promise.resolve(false),
  getUIVersion: () => DEFAULT_UI_VERSION,
  confirmSignIn: (code: string) => Promise.reject(),
  completeNewPassword: (password: string) => Promise.reject(),
  federatedSignIn: (code: string, redirect_uri: string) => Promise.reject(),
  oauthSignIn: (email: string, redirect_uri: string) =>
    err(new AuthError({ name: "UNKNOWN", message: "Unknown" })),
  signOut: () => Promise.resolve(),
  signIn: (username: string, password: string) => Promise.reject(),
  register: (email: string, password: string, fullName: string) =>
    Promise.reject(),
  confirmUser: (
    username: string,
    code: string,
    email: string,
    signal?: AbortSignal
  ) => Promise.reject(),
  resendConfirmation: (username: string, signal?: AbortSignal) =>
    Promise.reject(),
  confirmForgotPassword: (username: string, code: string, password: string) =>
    Promise.reject(),
  forgotPassword: (username: string) => Promise.reject(),
});

export default AuthContext;
