export const TOKEN_ENDPOINT_ERROR = {
  INVALID_REQUEST: "invalid_request",
  INVALID_GRANT: "invalid_grant",
};

export const COGNITO_EXCEPTION = {
  USER_NOT_FOUND: "UserNotFoundException",
  NOT_AUTHORIZED: "NotAuthorizedException",
  USER_NOT_CONFIRMED: "UserNotConfirmedException",
  PASSWORD_RESET_REQUIRED: "PasswordResetRequiredException",
  PASSWORD_HISTORY_POLICY: "PasswordHistoryPolicyViolationException",
  INVALID_PASSWORD: "InvalidPasswordException",
  EXPIRED_CODE: "ExpiredCodeException",
  CODE_MISMATCH: "CodeMismatchException",
};

export const CHALLENGE_NAME = {
  CUSTOM_CHALLENGE: "CUSTOM_CHALLENGE",
  MFA_SETUP: "MFA_SETUP",
  NEW_PASSWORD_REQUIRED: "NEW_PASSWORD_REQUIRED",
  SELECT_MFA_TYPE: "SELECT_MFA_TYPE",
  SOFTWARE_TOKEN_MFA: "SOFTWARE_TOKEN_MFA",
  SMS_MFA: "SMS_MFA",
};

export const AXIO_TOKEN = "Axio.token";
export const AXIO_REFRESH_TOKEN = "Axio.refreshToken";
