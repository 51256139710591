import validator from "validator";
import AuthError from "../AuthError";
import AxioJwtToken from "../AxioJwtToken";
import AxioRefreshToken from "../AxioRefreshToken";
import AxioUserSession from "../AxioUserSession";
import { ok, okAsync, err, errAsync, Result, ResultAsync } from "neverthrow";
import {
  request,
  handleResponse,
  encodeCredentials,
  getAxioUserSession,
  getChallenge,
} from "./Auth.utils";
import {
  AXIO_TOKEN,
  AXIO_REFRESH_TOKEN,
  CHALLENGE_NAME,
} from "./Auth.constants";
import type {
  AxioUser,
  UserData,
  MfaSettings,
  MfaMethod,
  UserAttribute,
} from "./Auth.types";

class Auth {
  private readonly domainUrl: string;
  private signInUserSession: AxioUserSession | null;

  constructor(domain: string) {
    this.signInUserSession = null;
    this.domainUrl = !/^https?:\/\//.test(domain)
      ? `https://${domain}`
      : domain;
  }

  private getUrl(endpoint: string): Result<URL, AuthError> {
    if (!URL.canParse(endpoint, this.domainUrl)) {
      return err(
        new AuthError({
          name: "INVALID_URL",
          message: `Invalid URL (url = ${endpoint}, base = ${this.domainUrl})`,
        })
      );
    }

    return ok(new URL(endpoint, this.domainUrl));
  }

  private getMfaSettings(
    mfaMethod: MfaMethod,
    data: UserData
  ): Result<MfaSettings, AuthError> {
    const mfaList = data.UserMFASettingList;
    const currentMFAType = this.getMfaTypeFromUserData(data);
    const mfaSettings: MfaSettings = {};

    if (mfaMethod === "SOFTWARE_TOKEN_MFA") {
      mfaSettings.softwareToken = {
        PreferredMfa: true,
        Enabled: true,
      };
    } else if (mfaMethod === "SMS_MFA") {
      mfaSettings.sms = {
        PreferredMfa: true,
        Enabled: true,
      };
    } else if (mfaMethod === "NOMFA") {
      if (currentMFAType === "NOMFA") {
        return ok(mfaSettings);
      } else if (currentMFAType === "SMS_MFA") {
        mfaSettings.sms = {
          PreferredMfa: false,
          Enabled: false,
        };
      } else if (currentMFAType === "SOFTWARE_TOKEN_MFA") {
        mfaSettings.softwareToken = {
          PreferredMfa: false,
          Enabled: false,
        };
      } else {
        return err(
          new AuthError({
            name: "INVALID_MFA_TYPE",
            message: "Invalid MFA Type",
          })
        );
      }

      // if there is a UserMFASettingList in the response
      // we need to disable every mfa type in that list
      if (mfaList && mfaList.length !== 0) {
        // to disable SMS or TOTP if exists in that list
        mfaList.forEach((mfaType) => {
          if (mfaType === "SMS_MFA") {
            mfaSettings.sms = {
              PreferredMfa: false,
              Enabled: false,
            };
          } else if (mfaType === "SOFTWARE_TOKEN_MFA") {
            mfaSettings.softwareToken = {
              PreferredMfa: false,
              Enabled: false,
            };
          }
        });
      }
    }

    return ok(mfaSettings);
  }

  private getMfaTypeFromUserData(data: UserData) {
    let result;

    // if the user has used Auth.setPreferredMFA() to setup the mfa type
    // then the "PreferredMfaSetting" would exist in the response
    if (data.PreferredMfaSetting) {
      result = data.PreferredMfaSetting;
    } else {
      // if mfaList exists but empty, then its noMFA
      const mfaList = data.UserMFASettingList;

      if (!mfaList) {
        // if SMS was enabled by using Auth.enableSMS(),
        // the response would contain MFAOptions
        // as for now Cognito only supports for SMS, so we will say it is 'SMS_MFA'
        // if it does not exist, then it should be NOMFA
        const MFAOptions = data.MFAOptions;

        if (MFAOptions) {
          result = "SMS_MFA";
        } else {
          result = "NOMFA";
        }
      } else if (mfaList.length === 0) {
        result = "NOMFA";
      } else {
        console.debug("invalid case for getPreferredMFA", data);
      }
    }

    return result;
  }

  private getUserData(): ResultAsync<UserData, AuthError> {
    if (this.signInUserSession == null || !this.signInUserSession.isValid()) {
      return errAsync(
        new AuthError({
          name: "MISSING_SESSION",
          message: "User is not authenticated",
        })
      );
    }

    const token = this.signInUserSession.getToken().getJwtToken();
    const url = this.getUrl("/v2/authentication/user");

    if (url.isErr()) {
      return errAsync(url.error);
    }

    return request(url.value, {
      headers: {
        credentials: "include",
        Authorization: `Bearer ${token}`,
      },
    })
      .andThen(handleResponse)
      .andThen((result: object) => {
        return okAsync(result as UserData);
      });
  }

  private setUserMfaPreference(
    mfaSettings: MfaSettings
  ): ResultAsync<void, AuthError> {
    if (this.signInUserSession == null || !this.signInUserSession.isValid()) {
      return errAsync(
        new AuthError({
          name: "MISSING_SESSION",
          message: "User is not authenticated",
        })
      );
    }

    const token = this.signInUserSession.getToken().getJwtToken();
    const url = this.getUrl("/v2/authentication/user/mfa-preference");

    if (url.isErr()) {
      return errAsync(url.error);
    }

    return request(url.value, {
      method: "POST",
      headers: {
        credentials: "include",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(mfaSettings),
    })
      .andThen(handleResponse)
      .andThen(() => okAsync(undefined));
  }

  private cleanClientData() {
    this.signInUserSession = null;

    localStorage.removeItem(AXIO_TOKEN);
    localStorage.removeItem(AXIO_REFRESH_TOKEN);
  }

  private cacheTokens() {
    if (this.signInUserSession === null) {
      return;
    }

    localStorage.setItem(
      AXIO_TOKEN,
      this.signInUserSession.getToken().getJwtToken()
    );

    localStorage.setItem(
      AXIO_REFRESH_TOKEN,
      this.signInUserSession.getRefreshToken().getToken()
    );
  }

  private revokeToken(): ResultAsync<void, AuthError> {
    if (!this.signInUserSession) {
      return errAsync(
        new AuthError({
          name: "MISSING_SESSION",
          message: "User is not authenticated",
        })
      );
    }

    const refreshToken = this.signInUserSession.getRefreshToken().getToken();

    if (!refreshToken) {
      return okAsync(undefined);
    }

    const url = this.getUrl("/v2/authentication/revoke");

    if (url.isErr()) {
      return errAsync(url.error);
    }

    return request(url.value, {
      method: "POST",
      headers: {
        credentials: "include",
        Authorization: `Bearer ${refreshToken}`,
      },
    })
      .andThen(handleResponse)
      .andThen(() => okAsync(undefined));
  }

  public register(
    email: string,
    password: string,
    fullName: string
  ): ResultAsync<{ message: string; username: string }, AuthError> {
    if (!validator.isEmail(email)) {
      return errAsync(
        new AuthError({
          name: "INVALID_EMAIL",
          message: "Invalid email",
        })
      );
    }

    const url = this.getUrl("v2/authentication/user/register");

    if (url.isErr()) {
      return errAsync(url.error);
    }

    return request(url.value, {
      method: "POST",
      headers: {
        credentials: "include",
        "Content-Type": "application/json",
        Authorization: `Basic ${encodeCredentials(
          email.toLowerCase(),
          password
        )}`,
      },
      body: JSON.stringify({ fullName }),
    }).andThen(handleResponse) as ResultAsync<
      { message: string; username: string },
      AuthError
    >;
  }

  public confirmUser(
    username: string,
    code: string,
    email: string,
    signal?: AbortSignal
  ): ResultAsync<object, AuthError> {
    if (!validator.isEmail(email)) {
      return errAsync(
        new AuthError({
          name: "INVALID_EMAIL",
          message: "Invalid email",
        })
      );
    }

    const url = this.getUrl("v2/authentication/user/register/confirm");

    if (url.isErr()) {
      return errAsync(url.error);
    }

    return request(url.value, {
      signal: signal,
      method: "POST",
      headers: { "Content-Type": "application/json", credentials: "include" },
      body: JSON.stringify({
        email: email.toLowerCase(),
        username,
        code,
      }),
    }).andThen(handleResponse);
  }

  public resendConfirmation(
    username: string,
    signal?: AbortSignal
  ): ResultAsync<object, AuthError> {
    const url = this.getUrl("v2/authentication/user/register/resend");

    if (url.isErr()) {
      return errAsync(url.error);
    }

    return request(url.value, {
      signal: signal,
      method: "POST",
      headers: { "Content-Type": "application/json", credentials: "include" },
      body: JSON.stringify({ username }),
    }).andThen(handleResponse);
  }

  public forgotPassword(username: string): ResultAsync<void, AuthError> {
    if (!validator.isEmail(username)) {
      return errAsync(
        new AuthError({
          name: "INVALID_EMAIL",
          message: "Username is not a valid email.",
        })
      );
    }

    const url = this.getUrl("/v2/authentication/forgot-password");

    if (url.isErr()) {
      return errAsync(url.error);
    }

    return request(url.value, {
      method: "POST",
      headers: { "Content-Type": "application/json", credentials: "include" },
      body: JSON.stringify({
        username: username.toLowerCase(),
      }),
    })
      .andThen(handleResponse)
      .andThen(() => okAsync(undefined));
  }

  public confirmForgotPassword(
    username: string,
    code: string,
    password: string
  ): ResultAsync<void, AuthError> {
    if (!validator.isEmail(username)) {
      return errAsync(
        new AuthError({
          name: "INVALID_EMAIL",
          message: "Username is not a valid email.",
        })
      );
    }

    const url = this.getUrl("/v2/authentication/forgot-password/confirm");

    if (url.isErr()) {
      return errAsync(url.error);
    }

    return request(url.value, {
      method: "POST",
      headers: { "Content-Type": "application/json", credentials: "include" },
      body: JSON.stringify({
        username: username.toLowerCase(),
        password,
        code,
      }),
    })
      .andThen(handleResponse)
      .andThen(() => okAsync(undefined));
  }

  public completeNewPassword(
    username: string,
    password: string
  ): ResultAsync<AxioUser, AuthError> {
    const url = this.getUrl("/v2/authentication/new-password");

    if (url.isErr()) {
      return errAsync(url.error);
    }

    return request(url.value, {
      method: "POST",
      headers: { "Content-Type": "application/json", credentials: "include" },
      body: JSON.stringify({ username, password }),
    })
      .andThen(handleResponse)
      .andThen((result: object) => {
        const { challengeName, challengeParam } = getChallenge(result);

        if (
          challengeName &&
          Object.values(CHALLENGE_NAME).includes(challengeName)
        ) {
          return okAsync({
            username,
            challengeName,
            challengeParam,
          } as AxioUser);
        }

        const session = (this.signInUserSession = getAxioUserSession(result));

        this.cacheTokens();

        return okAsync({
          username,
          session,
          challengeName,
          challengeParam,
        } as AxioUser);
      });
  }

  public signIn(
    username: string,
    password: string
  ): ResultAsync<AxioUser, AuthError> {
    if (!validator.isEmail(username)) {
      return errAsync(
        new AuthError({
          name: "INVALID_EMAIL",
          message: "Username is not a valid email.",
        })
      );
    }

    const _username = username.toLowerCase();
    const url = this.getUrl("/v2/authentication");

    if (url.isErr()) {
      return errAsync(url.error);
    }

    return request(url.value, {
      headers: {
        credentials: "include",
        Authorization: `Basic ${encodeCredentials(_username, password)}`,
      },
    })
      .andThen(handleResponse)
      .andThen((result: object) => {
        const { challengeName, challengeParam } = getChallenge(result);

        if (
          challengeName &&
          Object.values(CHALLENGE_NAME).includes(challengeName)
        ) {
          return okAsync({
            username: _username,
            challengeName,
            challengeParam,
          } as AxioUser);
        }

        const session = (this.signInUserSession = getAxioUserSession(result));

        this.cacheTokens();

        return okAsync({
          username: _username,
          session,
          challengeName,
          challengeParam,
        } as AxioUser);
      });
  }

  public confirmSignIn(
    username: string,
    code: string
  ): ResultAsync<AxioUser, AuthError> {
    if (!validator.isEmail(username)) {
      return errAsync(
        new AuthError({
          name: "INVALID_EMAIL",
          message: "Username is not a valid email.",
        })
      );
    }

    const _username = username.toLowerCase();
    const url = this.getUrl("/v2/authentication/mfa");

    if (url.isErr()) {
      return errAsync(url.error);
    }

    return request(url.value, {
      method: "POST",
      headers: { "Content-Type": "application/json", credentials: "include" },
      body: JSON.stringify({
        username: _username,
        code,
      }),
    })
      .andThen(handleResponse)
      .andThen((result: object) => {
        const session = (this.signInUserSession = getAxioUserSession(result));

        this.cacheTokens();

        return okAsync({
          username: _username,
          session,
        } as AxioUser);
      });
  }

  public federatedSignIn(
    code: string,
    redirect_uri: string
  ): ResultAsync<AxioUser, AuthError> {
    const url = this.getUrl("/v2/authentication/oauth2");

    if (url.isErr()) {
      return errAsync(url.error);
    }

    return request(url.value, {
      method: "POST",
      headers: { "Content-Type": "application/json", credentials: "include" },
      body: JSON.stringify({ code, redirect_uri }),
    })
      .andThen(handleResponse)
      .andThen((result: object) => {
        const session = (this.signInUserSession = getAxioUserSession(result));

        this.cacheTokens();

        return okAsync({
          session,
        } as AxioUser);
      });
  }

  public oauthSignIn(
    email: string,
    redirect_uri: string
  ): Result<Window, AuthError> {
    if (!validator.isEmail(email)) {
      return err(
        new AuthError({
          name: "INVALID_EMAIL",
          message: "Invalid email",
        })
      );
    }

    const params = new URLSearchParams({
      redirect_uri,
      idp_identifier: email.split("@")[1],
    });

    const url = this.getUrl(
      `/v2/authentication/oauth2/authorize?${params.toString()}`
    );

    if (url.isErr()) {
      return err(url.error);
    }

    try {
      const windowProxy = window.open(url.value, "_self");

      if (!windowProxy) {
        return err(
          new AuthError({
            name: "UNKNOWN",
            message: "Window open error",
          })
        );
      }

      return ok(windowProxy);
    } catch (error) {
      return err(
        new AuthError({
          name: "UNKNOWN",
          message: "Window open error",
          cause: error,
        })
      );
    }
  }

  public signOut(
    revokeTokenCallback?: (result: Result<void, AuthError>) => void
  ): void {
    if (!revokeTokenCallback) {
      this.cleanClientData();

      return;
    }

    this.getSession()
      .andThen(() => this.revokeToken())
      .then((result) => {
        this.cleanClientData();

        revokeTokenCallback(result);
      });
  }

  public refreshSession(
    axioRefreshToken: AxioRefreshToken
  ): ResultAsync<AxioUserSession, AuthError> {
    const url = this.getUrl("/v2/authentication/refresh");

    if (url.isErr()) {
      return errAsync(url.error);
    }

    return request(url.value, {
      method: "POST",
      headers: {
        credentials: "include",
        Authorization: `Bearer ${axioRefreshToken.getToken()}`,
      },
    })
      .andThen(handleResponse)
      .andThen((result: object) => {
        this.signInUserSession = getAxioUserSession(result);

        this.cacheTokens();

        return okAsync(this.signInUserSession);
      });
  }

  public getPreferredMFA(): ResultAsync<string, AuthError> {
    return this.getUserData().andThen((data) => {
      const mfaType = this.getMfaTypeFromUserData(data);

      if (!mfaType) {
        return errAsync(
          new AuthError({
            name: "INVALID_MFA_TYPE",
            message: "Invalid MFA Type",
          })
        );
      }

      return okAsync(mfaType);
    });
  }

  public setPreferredMFA(mfaMethod: MfaMethod): ResultAsync<void, AuthError> {
    return this.getUserData().andThen((data) => {
      const mfaSettings = this.getMfaSettings(mfaMethod, data);

      if (mfaSettings.isErr()) {
        return errAsync(mfaSettings.error);
      }

      return this.setUserMfaPreference(mfaSettings.value);
    });
  }

  public updateUserAttributes(
    attributes: UserAttribute[]
  ): ResultAsync<void, AuthError> {
    if (this.signInUserSession == null || !this.signInUserSession.isValid()) {
      return errAsync(
        new AuthError({
          name: "MISSING_SESSION",
          message: "User is not authenticated",
        })
      );
    }

    const token = this.signInUserSession.getToken().getJwtToken();
    const url = this.getUrl("/v2/authentication/user/update-attribute");

    if (url.isErr()) {
      return errAsync(url.error);
    }

    return request(url.value, {
      method: "POST",
      headers: {
        credentials: "include",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ attributes }),
    })
      .andThen(handleResponse)
      .andThen(() => okAsync(undefined));
  }

  public verifyUserAttributeSubmit(
    attribute: string,
    code: string
  ): ResultAsync<void, AuthError> {
    if (this.signInUserSession == null || !this.signInUserSession.isValid()) {
      return errAsync(
        new AuthError({
          name: "MISSING_SESSION",
          message: "User is not authenticated",
        })
      );
    }

    const token = this.signInUserSession.getToken().getJwtToken();
    const url = this.getUrl("/v2/authentication/user/verify-attribute-submit");

    if (url.isErr()) {
      return errAsync(url.error);
    }

    return request(url.value, {
      method: "POST",
      headers: {
        credentials: "include",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ attribute, code }),
    })
      .andThen(handleResponse)
      .andThen(() => okAsync(undefined));
  }

  public verifyUserAttributeInit(
    attribute: string
  ): ResultAsync<void, AuthError> {
    if (this.signInUserSession == null || !this.signInUserSession.isValid()) {
      return errAsync(
        new AuthError({
          name: "MISSING_SESSION",
          message: "User is not authenticated",
        })
      );
    }

    const token = this.signInUserSession.getToken().getJwtToken();
    const url = this.getUrl("/v2/authentication/user/verify-attribute-init");

    if (url.isErr()) {
      return errAsync(url.error);
    }

    return request(url.value, {
      method: "POST",
      headers: {
        credentials: "include",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ attribute }),
    })
      .andThen(handleResponse)
      .andThen(() => okAsync(undefined));
  }

  public getSession(): ResultAsync<AxioUserSession, AuthError> {
    if (this.signInUserSession != null && this.signInUserSession.isValid()) {
      return okAsync(this.signInUserSession);
    }

    const axioJwtToken = new AxioJwtToken({
      token: localStorage.getItem(AXIO_TOKEN) || "",
    });

    if (!axioJwtToken.getJwtToken()) {
      return errAsync(
        new AuthError({
          name: "MISSING_TOKEN",
          message:
            "Local storage is missing an Axio token, Please authenticate",
        })
      );
    }

    const axioRefreshToken = new AxioRefreshToken({
      refreshToken: localStorage.getItem(AXIO_REFRESH_TOKEN) || "",
    });

    const cachedSession = new AxioUserSession(axioJwtToken, axioRefreshToken);

    if (cachedSession.isValid()) {
      this.signInUserSession = cachedSession;

      return okAsync(this.signInUserSession);
    }

    if (!axioRefreshToken.getToken()) {
      return errAsync(
        new AuthError({
          name: "MISSING_REFRESH_TOKEN",
          message:
            "Local storage is missing an Axio refresh token, Please authenticate",
        })
      );
    }

    return this.refreshSession(axioRefreshToken);
  }

  public getSignInUserSession(): AxioUserSession | undefined {
    return this.signInUserSession || undefined;
  }

  public getSsoStatus(email: string): ResultAsync<boolean, AuthError> {
    if (!validator.isEmail(email)) {
      return errAsync(
        new AuthError({
          name: "INVALID_EMAIL",
          message: "Invalid email",
        })
      );
    }

    const url = this.getUrl("/idp");

    if (url.isErr()) {
      return errAsync(url.error);
    }

    return request(url.value, {
      method: "POST",
      headers: {
        credentials: "include",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: { email },
      }),
    })
      .andThen(handleResponse)
      .andThen((result: object) => {
        return okAsync(
          "data" in result && typeof result.data === "boolean" && result.data
        );
      });
  }
}

export default Auth;
